/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

mwl-gauge {
  width: 450px;
  height: 450px;
  display: block;
  padding: 10px;
}
mwl-gauge > .gauge > .dial {
  stroke: #dedede;
  stroke-width: 16;
  fill: rgba(0, 0, 0, 0);
}
mwl-gauge > .gauge > .value {
  stroke: #6dbe4e;
  stroke-width: 16;
  fill: rgba(0, 0, 0, 0);
}
mwl-gauge > .gauge > .value-text {
  fill: #6dbe4e;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 12px;
}
